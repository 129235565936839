import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { ScenarioState } from '../../core/@types/scenario';
// utils
import axios from '../../core/utils/axios';

// ----------------------------------------------------------------------

const initialState: ScenarioState = {
    scenario: null,
    steps: [],
    isLoading: false,
    error: null,
};

const slice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.error = null;
        },

        // GET SCENARIO
        getScenarioSuccess(state, action) {
            state.isLoading = false;
            state.scenario = action.payload;
            if (action.payload.scenarioSteps) {
                state.steps = action.payload.scenarioSteps;
            }
        },

        // GET STEPS
        getScenarioStepsSuccess(state, action) {
            state.isLoading = false;
            state.steps = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

type getProps = { keyword?: string, id?: number, withStepButtons?: any, withSteps?: any }

export function getScenario(BotId: number,
    { keyword, id, withSteps, withStepButtons }: getProps) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.get(`scenarios/scenario/${BotId}`, {
                params: {
                    withSteps: withSteps ? 1 : "",
                    withStepButtons: withStepButtons ? 1 : "",
                    keyword: keyword ?? "",
                    id: id ?? ""
                }
            });
            dispatch(slice.actions.getScenarioSuccess(result.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getStepOrScenario(BotId: number,
    { keyword, id, withSteps, withStepButtons }: getProps) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.get(`scenarios/step/${BotId}`, {
                params: {
                    withSteps: withSteps ? 1 : "",
                    withStepButtons: withStepButtons ? 1 : "",
                    keyword: keyword ?? "",
                    id: id ?? ""
                }
            });
            dispatch(slice.actions.getScenarioStepsSuccess([result.data]));
        } catch (error) {
            try {
                const result = await axios.get(`scenarios/scenario/${BotId}`, {
                    params: {
                        withSteps: withSteps ? 1 : "",
                        withStepButtons: withStepButtons ? 1 : "",
                        keyword: keyword ?? "",
                        id: id ?? ""
                    }
                });
                dispatch(slice.actions.getScenarioSuccess(result.data));
            } catch (error2) {
                dispatch(slice.actions.hasError(error));
            }
        }
    }
}