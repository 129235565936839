import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import WidgetLayout from '../core/layouts/mobile/WidgetLayout';
import OutsetLayout from '../core/layouts/mobile/OutsetLayout';

// TODO: Анастасия: отрефакторить noTopFilter
//
import {
  // Outset
  OutsetMainPage,
  OutsetLoginPage,
  OutsetRegisterPage,
  OutsetRegisterQuestionsPage,
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  MainUserPage,
  TrainerPage,
  Calendar,
  Exercise,
  ExerciseFull,
  More,
  Settings,
  Account,
  MyPlan,
  Events,
  Group,
  Tender,
  MainTrainerPage,
  TenderPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
       // Outset

    {
      // path: 'outset',
      element: <OutsetLayout noTopBar noBottomBar noTopFilter />,
      children: [
        {
          element: (
              <OutsetMainPage/>
          ),
          index: true,
          path: '/'
        },
        {
          element: (
            <OutsetLoginPage />
          ),
          path: 'login',
        },
        {
          element: (
            <OutsetRegisterPage />
          ),
          path: 'register',
        },
        {
          element: (
            <OutsetRegisterQuestionsPage />
          ),
          path: 'register/questions',
        },
        {
          element: (
              <MainUserPage />
          ),
          path: 'main-user',
        },
        {
          element: (
              <TrainerPage />
          ),
          path: 'main-user/trainer/:id',
        },
        {
          element: (
              <Calendar />
          ),
          path: 'main-user/calendar',
        },
        {
          element: (
              <Exercise />
          ),
          path: 'main-user/exercise',
        },
        {
          element: (
              <ExerciseFull />
          ),
          path: 'main-user/exercise-full',
        },
        {
          element: (
              <More />
          ),
          path: 'main-user/more',
        },
        {
          element: (
              <Settings />
          ),
          path: 'main-user/settings',
        },
        {
          element: (
              <Account />
          ),
          path: 'main-user/account',
        },
        {
          element: (
              <MyPlan />
          ),
          path: 'main-user/my-plan',
        },
        {
          element: (
              <Events />
          ),
          path: 'main-user/events',
        },
        {
          element: (
              <Group />
          ),
          path: 'main-user/group',
        },
          {
          element: (
              <Tender />
          ),
          path: 'main-user/tender',
        },

        {
          element: (
              <MainTrainerPage />
          ),
          path: 'main-trainer',
        },
        // {
        //   element: (
        //       <TenderPage />
        //   ),
        //   path: 'main-user/tender/:id',
        // }
      ],
    },

    // Main Layout
    {
      element: <OutsetLayout noTopFilter />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
