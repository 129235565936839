import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { WidgetState } from '../../core/@types/widget';
// utils
import axios from '../../core/utils/axios';

// ----------------------------------------------------------------------

const initialState: WidgetState = {
    title: '',
    BotId: 0,
    connection: {},
    isLoading: false,
    error: null,
};

const slice = createSlice({
    name: 'widget',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        getConnectionSuccess(state, action) {
            state.isLoading = false;
            state.connection = action.payload;
            state.connection.settings = JSON.parse(action.payload.settings);
            state.BotId = state.connection.bot.id;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SET TITLE
        setTitle(state, action) {
            state.title = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setTitle(title: string) {
    return (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.setTitle(title));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getConnection(token = process.env.REACT_APP_CONNECTION_TOKEN) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.get(`connection/byToken/${token}?bot=1`);
            dispatch(slice.actions.getConnectionSuccess(result.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}