import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Global } from '@emotion/react';
// @mui
import {
  Box,
  Card,
  Drawer,
  Collapse,
} from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// sections
import DefaultHeader from './header/DefaultHeader';
import DefaultFooter from './footer/DefaultFooter';
import MobileLayoutWrapper from './MobileLayoutWrapper';
// import { ProfileCover } from './menu';
// sections
// import { ProductsFilterDrawer } from '../../sections/@widget/e-commerce/menu';
// import ProductDialog from '../../sections/@widget/e-commerce/list/ProductDialog';
// _mock_
import { _userAbout } from '../../_mock/arrays';
import NavSearchFilter from './nav/NavSearchFilter';
import OutsetMobileLayout from './OutsetMobileLayout';

type OutsetProps = {
  noScroll?: boolean;
  noBottomBar?: boolean;
  noTopBar?: boolean;
  noTopFilter?: boolean;
};

function Outset({ noScroll, noBottomBar, noTopBar, noTopFilter }: OutsetProps) {
  const { user } = useAuthContext();
  
  const cardRef = useRef(null);
  const [aux, setAux] = useState({ topBar: 0, bottomBar: 0, navPages: 0 });
  const [menuOpen, setMenuOpen] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isAuth, setIsAuth] = useState(true);

  const handleLogout = () => {
    if (isAuth) {
      setIsAuth(false);
    }
  };

  return (
    <OutsetMobileLayout>
      <Card sx={{ height: '100%', display: 'flex', flexFlow: 'column' }} ref={cardRef}>
        <Collapse in={!noTopBar}>
          <DefaultHeader
            setHeight={(h) => setAux((state) => ({ ...state, topBar: h }))}
            toggleMenu={() => setMenuOpen(!menuOpen)}
          />
        </Collapse>
        <Collapse in={!noTopFilter}  >
          {/* <NavTabPages
            setHeight={(h) => setAux((state) => ({ ...state, navPages: h }))}
          /> */}
          <NavSearchFilter
            toggleFilterMenu={() => setFilterMenuOpen(!filterMenuOpen)}
            setHeight={(h) => setAux((state) => ({ ...state, navPages: h }))}
          />
        </Collapse>
        {noScroll ? (
          <Outlet />
        ) : (
          <Scrollbar
            sx={{
              pt: `${noTopBar ? 0 : aux.topBar}px`,
              pb: `${noBottomBar ? 0 : aux.bottomBar}px`,
            }}
          >
            <Outlet />
          </Scrollbar>
        )}
        <Collapse in={!noBottomBar}>
          <DefaultFooter setHeight={(h) => setAux((state) => ({ ...state, bottomBar: h }))} />
        </Collapse>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiBackdrop-root, .MuiDrawer-root > .MuiPaper-root': {
              position: 'absolute',
            },
          }}
        />
        <Drawer
          anchor="left"
          container={cardRef.current}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          sx={{ position: 'absolute' }}
          PaperProps={{ sx: { width: '80%' } }}
        >
          <Box
            sx={{
              borderRadius: 0,
              height: 120,
              position: 'relative',
            }}
          >
            {/* <ProfileCover
              toggleMenu={() => setMenuOpen(!menuOpen)}
              toggleAuth={handleLogout}
              isAuth={isAuth}
              name={user?.displayName}
              role={_userAbout.role}
              cover={_userAbout.cover}
              photoUrl={user?.photoURL}
            /> */}
          </Box>
        </Drawer>
        <Drawer
          anchor="right"
          container={cardRef.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          sx={{ position: 'absolute' }}
          PaperProps={{ sx: { width: '80%' } }}
        >
          <Box
            sx={{
              borderRadius: 0,
              height: 120,
              position: 'relative',
            }}
          >
            {/* <ProductsFilterDrawer */}
            {/*  toggleMenu={() => setFilterMenuOpen(false)} */}
            {/* /> */}
          </Box>
        </Drawer>
      </Card>
    </OutsetMobileLayout>
  );
}

export default Outset;