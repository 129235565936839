import SHEETS from './sheets_translation.json'

const formatted = SHEETS.reduce((acc: Record<string, any>, record) => {
  acc[record.English] = record.Hebrew
  return acc;
}, {})

const he = {
  demo: {
    title: `עברית`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  'Confirm password': 'אשר סיסמה',
  'Create account': 'צור חשבון',
  'Already have an account?': 'כבר יש לך חשבון?',
  'By signing up, I agree to': 'על ידי ההרשמה, אני מסכים',
  'and': 'ו',
  'Terms of Use': 'תנאי שימוש',
  'Privacy Policy': 'מדיניות פרטיות',
  'Save Changes': 'שמור שינויים',
  'About': 'אודות',
  'Zip/Code': 'מיקוד / קוד',
  'City': 'עיר',
  'State/Region': 'מדינה / אזור',
  'Country': 'מדינה',
  'Phone Number': 'מספר טלפון',
  'Email Address': 'דוא"ל',
  'Name': 'שם',
  'Login by ID': 'התחברות בעזרת תעודה מזהה',
  'New common invite': 'ניהול הזמנות לאירועים',
  'New one day invite': 'ניהול הזמנות יומי',
  'Logout': 'להתנתק',
  'Create invite': 'יצירת הזמנה',
  'Event': 'אירוע',
  'SMS': 'מסרון',
  'WhatsApp': 'וואטסאפ',
  'Guest name': 'שם האורח',
  'Car number': 'מספר רכב',
  'All': 'הכל',
  'Common': 'אישור חד יומי',
  'Sms message': 'הודעה',
  'Email message': 'הודעת דואר אלקטרוני',
  'There is no scenario with such keyword': 'אין תרחיש עם מילת מפתח כזו',
  'Add the guest': 'הוספת האורח',
  'Create': 'ליצור',
  'Extra': 'תוספת',
  'Profile': 'פרופיל',
  'Invitations are not allowed to this event': 'לא ניתן להזמין הזמנות לאירוע זה',
  'The guest is not specified': 'האורח לא מצוין',
  'Guests': 'אורחים',
  'Upload photo': 'העלאת תמונה',
  'Allowed': 'מורשה',
  'max size of': 'גודל מקסימלי',
  'Update photo': 'עדכן תמונה',
  'Event name': 'שם האירוע',
  'Required credits': 'קרדיטים נדרשים',
  'Date from': 'מהתאריך',
  'Date to': 'עד התאריך',
  'Create event': 'יצירת אירוע',
  'Add more guests': 'להוסיף אורחים נוספים',
  'The event is not specified': 'האירוע לא צויין',
  'Show history': 'הצג היסטוריה',
  'Hide history': 'הסתר היסטוריה',
  'Full name': 'שם מלא',
  'Date': 'תאריך',
  'Status': 'מעמד',
  'SMS message': 'הודעת SMS',
  'Channels': 'ערוצים',
  'Sent': 'נשלח',
  'Declined': 'נדחה',
  'Used': 'שומש',
  'Start': 'התחלה',
  'Send': 'שלח',
  'The confirmation was registered successfully!': 'האישור נרשם בהצלחה',
  'Email': 'דוא"ל',
  'from': 'מ',
  'to': 'עד',
  'Accept agreement': 'קבל הסכם',
  ...formatted,
}

export default he;