import {createSlice, Dispatch} from '@reduxjs/toolkit';
// @types
import {TrainingState} from "../../core/@types/training";

// ----------------------------------------------------------------------

const initialState: TrainingState = {
    open: false
};

const slice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        setOpenTraining(state, action) {
            state.open = action.payload;
        },
        setCloseTraining(state, action) {
            state.open = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setOpenTraining(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setOpenTraining(payload))
    }
}

export function setCloseTraining(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setCloseTraining(payload))
    }
}