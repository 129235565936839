import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Grid,
  Stack,
  Typography,
  Collapse,
  InputBase,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
// components
import Iconify from '../../../components/iconify';
// redux
import { useSelector, useDispatch } from '../../../../redux/store';
import { setTitle } from '../../../../redux/slices/widget';
// locales
import { useLocales } from '../../../locales';
// utils
import { bgBlur } from '../../../utils/cssStyles';

type Props = {
  setHeight: (arg0: number) => void;
  toggleMenu: VoidFunction;
};

const DefaultHeader = (props: Props) => {
  const { setHeight, toggleMenu } = props;

  const { title, connection } = useSelector((state) => state.widget);

  const theme = useTheme();
  const ref = useRef(null);
  const { translate } = useLocales();

  const [showSearchBar, setShowSearchBar] = useState(false);
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (ref?.current) {
      const el = ref.current as HTMLDivElement;
      setHeight(el.offsetHeight);
    }
  }, [ref.current]); //eslint-disable-line

  return (
    <AppBar
      position="absolute"
      color="transparent"
      sx={{
        top: 0,
        bottom: 'auto',
        boxShadow: 0,
        ...bgBlur({ color: theme.palette.background.default }),
      }}
      // @ts-ignore
      ref={ref}
    >
      <Toolbar>
        <Grid container>
          <Grid item xs={3} sx={{ textAlign: 'left' }} justifyContent="flex-start">
            <IconButton color="inherit" aria-label="back" onClick={handleNavigateBack}>
              <Iconify
                icon={
                  theme.direction === 'ltr'
                    ? 'ic:baseline-keyboard-arrow-left'
                    : 'ic:baseline-keyboard-arrow-right'
                }
              />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: 'right',
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Typography textAlign="center" variant="subtitle1">
              {`${translate(title)}`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {connection?.settings?.showMenuBtn ?? true ? (
                <IconButton color="inherit" aria-label="open drawer" onClick={toggleMenu}>
                  <Iconify icon="ic:round-menu" />
                </IconButton>
              ) : (
                ''
              )}

              {/* <IconButton color="inherit" onClick={() => setShowSearchBar(!showSearchBar)}>
                <Iconify icon="ic:baseline-search" />
              </IconButton>
              <IconButton color="inherit">
                <Badge badgeContent={1} color="error">
                  <Iconify icon="mdi:bell-outline" />
                </Badge>
              </IconButton> */}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
      <Collapse in={showSearchBar}>
        <Toolbar>
          <Search>
            <SearchIconWrapper>
              <Iconify icon="mdi:search" color={theme.palette.primary.main} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={`${translate('Search')}`}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <IconButton>
            <Iconify icon="mdi:map-marker" width={24} color={theme.palette.primary.main} />
          </IconButton>
          <IconButton disableRipple>
            <Avatar sx={{ backgroundColor: theme.palette.primary.main }} variant="rounded">
              <Iconify icon="mdi:tune-vertical" color={theme.palette.common.white} width={24} />
            </Avatar>
          </IconButton>
        </Toolbar>
      </Collapse>
    </AppBar>
  );
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(
    theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
    0.05
  ),
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
      0.09
    ),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default DefaultHeader;

export function Title({ children }: { children: string }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTitle(children));
  }, [children]); //eslint-disable-line

  return <></>; //eslint-disable-line
}
