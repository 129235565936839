import { Outlet } from 'react-router-dom';
import { ReactElement } from 'react';
// @mui
import { Stack, Container } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config

// ----------------------------------------------------------------------

type Props = {
  children?: ReactElement;
};

export default function OutsetMobileLayout({ children }: Props) {
  const mobile = useMediaQuery('(max-width:400px)');

  return (
    <Container component="main" sx={{ p: 0 }}>
        <Stack
          sx={{
            py: mobile ? 0 : 12,
            m: 'auto',
            maxWidth: 400,
            maxHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
  );
}
