import {createSlice, Dispatch} from '@reduxjs/toolkit';
// @types
import {NewTaskState} from "../../core/@types/newTask";

// ----------------------------------------------------------------------

const initialState: NewTaskState = {
    open: false
};

const slice = createSlice({
    name: 'newTask',
    initialState,
    reducers: {
        setOpenTask(state, action) {
            state.open = action.payload;
        },
        setCloseTask(state, action) {
            state.open = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setOpenTask(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setOpenTask(payload))
    }
}

export function setCloseTask(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setCloseTask(payload))
    }
}