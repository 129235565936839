import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { CRMState } from '../../core/@types/crm';
// utils
import axios from '../../core/utils/axios';

// ----------------------------------------------------------------------

const initialState: CRMState = {
    events: [],
    eventHistory: [],
    isLoading: false,
    error: null,
    answer: null,
};

const slice = createSlice({
    name: 'crm',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.error = null;
        },

        resetAnswer(state) {
            state.answer = null;
        },

        getEventsSuccess(state, action) {
            state.isLoading = false;
            state.events = action.payload;
        },

        sendInviteSuccess(state, action) {
            state.isLoading = false;
            state.answer = action.payload;
        },

        getEventSuccess(state, action) {
            state.isLoading = false;
            state.eventHistory = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function resetAnswer() {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.resetAnswer())
    }
}

export function getEvents(type: string, BotId: number, offset = 0, limit = 100) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.get(`/crm/credit/${BotId}/${offset}/${limit}`, { params: { type } });
            dispatch(slice.actions.getEventsSuccess(result.data.items));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getEvent(eventId: number, count = 15, page = 0) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.get(`/crm/credit/${eventId}/invite/${page}/${count}`);
            dispatch(slice.actions.getEventSuccess(result.data.items));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function sendInvite(eventId: number, invites: Record<string, any>[]) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const result = await axios.post(`/crm/credit/${eventId}/invite`, { invites });
            dispatch(slice.actions.sendInviteSuccess(result.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}