import {createSlice, Dispatch} from '@reduxjs/toolkit';
// @types
import {TrainerState} from "../../core/@types/trainer";

// ----------------------------------------------------------------------

const initialState: TrainerState = {
    avatarUrl: '',
    id: '',
    kindOfSport: [],
    name: '',
    skills: '',
    placeOfResidence: 'Israel, Tel Aviv',
    experience: 8,
    rating: 4.9,
    age: 27,
    open: false
};

const slice = createSlice({
    name: 'trainer',
    initialState,
    reducers: {
        getTrainer(state, action) {
            state.avatarUrl = action.payload.avatarUrl;
            state.skills = action.payload.skills;
            state.id = action.payload.id;
            state.kindOfSport = action.payload.kindOfSport;
            state.name = action.payload.name;
        },
        setOpenTrainer(state, action) {
            state.open = action.payload;
        },
        setCloseTrainer(state, action) {
            state.open = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTrainer(array: any) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.getTrainer(array));
    }
}
export function setOpenTrainer(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setOpenTrainer(payload))
    }
}

export function setCloseTrainer(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setCloseTrainer(payload))
    }
}