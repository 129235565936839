import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import widgetReducer from './slices/widget';
import scenarioReducer from './slices/scenario';
import crmReducer from './slices/crm';
import tenderReducer from './slices/tender';
import trainerReducer from './slices/trainer';
import newTaskReducer from './slices/newTask';
import lastStepReducer from './slices/lastStep';
import trainingReducer from './slices/training';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  widget: widgetReducer,
  scenario: scenarioReducer,
  crm: crmReducer,
  trainer: trainerReducer,
  newTask: newTaskReducer,
  lastStep: lastStepReducer,
  training: trainingReducer,
  tender: tenderReducer
});

export default rootReducer;
