import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../core/components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// OUTSET
export const OutsetMainPage = Loadable(lazy(() => import('../pages/outset')));
export const OutsetLoginPage = Loadable(lazy(() => import('../pages/outset/LoginPage')));
export const OutsetRegisterPage = Loadable(lazy(() => import('../pages/outset/RegisterPage')));
export const OutsetRegisterQuestionsPage = Loadable(lazy(() => import('../pages/outset/RegisterQuestionsPage')));
export const MainUserPage = Loadable(lazy(() => import('../pages/outset/user/MainPage')));
export const TrainerPage = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/TrainerPage')));
export const Calendar = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Calendar')));
export const Exercise = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Exercise')));
export const ExerciseFull = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/ExerciseFull')));
export const More = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/More')));
export const Settings = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Settings')));
export const Account = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Account')));
export const MyPlan = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/MyPlan')));
export const Events = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Events')));
export const Group = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Group')));
export const Tender = Loadable(lazy(() => import('../core/sections/@outset/dashboard/user/components/Tender')));
export const MainTrainerPage = Loadable(lazy(() => import('../pages/outset/MainPage')));
export const TenderPage = Loadable(lazy(() => import('../core/sections/@outset/dashboard/components/TenderPage')));
