import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types';

// ----------------------------------------------------------------------

// TODO: сделать нормальное распределение на user и customer;

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    customer: AuthUserType;
  };
  [Types.LOGIN]: {
    customer: AuthUserType;
  };
  [Types.REGISTER]: {
    customer: AuthUserType;
  };
  [Types.UPDATE]: {
    customer: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.customer,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === Types.UPDATE) {
    return {
      ...state,
      user: action.payload.customer,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/auth/my-account');

        const { customer, user } = response.data;

        const finalUser = customer ? { ...customer, type: 'customer' } : { ...user, type: 'user' };

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            customer: finalUser,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            customer: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          customer: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (BotId: number | string, email: string, password: string, idKey?: string) => {
      const response = await axios.post(`/auth/auto/login/${BotId}`, {
        email,
        password,
        idKey,
      });

      const { accessToken, customer, user } = response.data;

      const finalUser = customer ? { ...customer, type: 'customer' } : { ...user, type: 'user' };

      setSession(accessToken);

      dispatch({
        type: Types.LOGIN,
        payload: {
          customer: finalUser,
        },
      });
    },
    []
  );

  // REGISTER
  const register = useCallback(
    async (
      BotId: number | string,
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      const response = await axios.post(`/auth/customer/register/${BotId}`, {
        email,
        password,
        firstName,
        lastName,
      });
      const { accessToken, customer, user } = response.data;

      localStorage.setItem('accessToken', accessToken);

      const finalUser = customer ? { ...customer, type: 'customer' } : { ...user, type: 'user' };

      dispatch({
        type: Types.REGISTER,
        payload: {
          customer: finalUser,
        },
      });
    },
    []
  );

  // UPDATE USER
  const updateUser = useCallback(async (newData: Record<string, any>) => {
    const response = await axios.post('/auth/update-account', newData);
    const { customer } = response.data;

    dispatch({
      type: Types.UPDATE,
      payload: {
        customer,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      updateUser,
      loginWithGoogle: () => {},
      loginWithGithub: () => {},
      loginWithTwitter: () => {},
      register,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, updateUser]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
