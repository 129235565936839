import {createSlice, Dispatch} from '@reduxjs/toolkit';
// @types
import {LastStepState} from "../../core/@types/lastStep";

// ----------------------------------------------------------------------

const initialState: LastStepState = {
    open: false
};

const slice = createSlice({
    name: 'lastStep',
    initialState,
    reducers: {
        setOpenLastStep(state, action) {
            state.open = action.payload;
        },
        setCloseLastStep(state, action) {
            state.open = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setOpenLastStep(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setOpenLastStep(payload))
    }
}

export function setCloseLastStep(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setCloseLastStep(payload))
    }
}