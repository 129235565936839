import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="512px" viewBox="0 0 24 24">
          <g fill={PRIMARY_MAIN}>
            <path
              fillRule="evenodd"
              d="M12 22c-4.418 0-8-3.646-8-8.143c0-4.462 2.553-9.67 6.537-11.531A3.453 3.453 0 0 1 12 2v20Z"
              clipRule="evenodd"
            />
            <path
              d="M13.463 2.326A3.453 3.453 0 0 0 12 2v7l4.432-4.432c-.863-.947-1.86-1.724-2.97-2.242Z"
              opacity=".3"
            />
            <path d="M12 9v5.5l6.614-6.614c-.572-1.22-1.308-2.357-2.182-3.318L12 9Z" opacity=".4" />
            <path d="m12 19.5l7.811-7.811a14.94 14.94 0 0 0-1.197-3.803L12 14.5v5Z" opacity=".6" />
            <path
              d="M19.811 11.689L12 19.5V22c4.418 0 8-3.646 8-8.143c0-.71-.064-1.438-.189-2.168Z"
              opacity=".7"
            />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
