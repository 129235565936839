// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
// date formats
import { he, eu, ru } from 'date-fns/locale';
// date
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
//
import useLocales from './useLocales';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export const LOCALES: Record<string, any> = {
  he,
  ru,
  en: eu,
};

export default function ThemeLocalization({ children }: Props) {
  const outerTheme = useTheme();

  const { currentLang } = useLocales();

  const theme = createTheme(outerTheme, currentLang.systemValue);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={currentLang?.value ? LOCALES[currentLang.value] : eu}
    >
      <ThemeProvider theme={theme}> {children} </ThemeProvider>
    </LocalizationProvider>
  );
}
