import {createSlice, Dispatch} from '@reduxjs/toolkit';
// @types
import {TenderState} from "../../core/@types/tender";

// ----------------------------------------------------------------------

const initialState: TenderState = {
    avatar: '',
    address: '',
    error: null,
    skills: '',
    description: '',
    sports: [],
    open: false
};

const slice = createSlice({
    name: 'tender',
    initialState,
    reducers: {
        getTender(state, action) {
            state.description = ' I would like to pump my core and abs muscles, ' +
                'but I have some health problems. Coras malesuada vestibulum imperdiet. ' +
                'Pellentesque tincidunt ante dignissim sem aliquam, ac consequat nisl efficitur. ' +
                'Coras malesuada vestibulum imperdiet. ' +
                'Pellentesque tincidunt an te dignissim sem aliquam, ac consequat nisl efficitur. ' +
                'Coras malesuada vestibulum imperdiet. Pellentesque tincidunt ante dignissim sem aliquam, ac consequat nisl efficitur. ' +
                'Coras malesuada vestibulum imperdiet';
            state.skills = 'Strengthening the bark and pumping press';
            state.address = 'Brooklyn Simmons, Tel Aviv';
            state.sports = ['Cardio 🫀', 'Power 🏋'];
            state.avatar = action.payload.avatar;
        },
        setOpenTender(state, action) {
            state.open = action.payload;
        },
        setCloseTender(state, action) {
            state.open = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTender(array: any) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.getTender(array));
    }
}
export function setOpenTender(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setOpenTender(payload))
    }
}

export function setCloseTender(payload: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(slice.actions.setCloseTender(payload))
    }
}