import { useState, useEffect, useRef } from 'react';
// @mui
import { Stack, TextField, InputAdornment, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
// sections
// import ProductsSort from '../../../sections/@widget/e-commerce/menu/ProductsSort';

// ----------------------------------------------------------------------

type NavSearchFilterType = {
  toggleFilterMenu?: VoidFunction;
  toggleSortMenu?: VoidFunction;
  setHeight: (arg0: number) => void;
};

export default function NavSearchFilter({
  toggleFilterMenu,
  toggleSortMenu,
  setHeight,
}: NavSearchFilterType) {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (ref?.current) {
      const el = ref.current as HTMLDivElement;
      setHeight(el.offsetHeight);
    }
  }, [ref.current]); //eslint-disable-line
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack
      width="329px"
      flexDirection="row"
      height="40px"
      justifyContent="space-between"
      alignItems="center"
      sx={{ ml: 1, mt: 8, mb: 6 }}
    >
      <TextField
        id="input-with-icon-textfield"
        placeholder="Search…"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="material-symbols:search" width="18px" height="18px" />
            </InputAdornment>
          ),
          sx: { width: '250px', height: '40px' },
        }}
        variant="outlined"
      />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton color="inherit" onClick={toggleFilterMenu}>
          <Iconify icon="material-symbols:filter-list-rounded" width="18px" height="12px" />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleClick}
          sx={{
            width: '34px',
            height: '34px',
            backgroundColor: open ? 'rgba(145, 158, 171, 0.16)' : 'none',
            borderRadius: '6px',
          }}
        >
          <Iconify icon="solar:sort-vertical-linear" width="18px" height="18px" />
        </IconButton>
        {/* <ProductsSort */}
        {/*  toggleMenu={handleClose} */}
        {/*  open={open} */}
        {/*  anchorEl={anchorEl} */}
        {/*  handleClose={handleClose} */}
        {/* /> */}
      </Stack>
    </Stack>
  );
}
