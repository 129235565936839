import { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// @mui
import { AppBar, Toolbar, IconButton, Avatar, Box, Stack, Badge, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
// import { PATH_CHAT, PATH_WIDGET } from '../../../../routes/paths';
// redux
import { useSelector } from '../../../../redux/store';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/iconify';
// locales
import useLocales from '../../../locales/useLocales';

type Props = {
  setHeight: (arg0: number) => void;
};

const DefaultFooter = (props: Props) => {
  const { setHeight } = props;
  const { isAuthenticated } = useAuthContext();

  const theme = useTheme();
  const ref = useRef(null);
  const { translate: t } = useLocales();

  const { totalItems } = useSelector((state) => state.product.checkout);
  const { connection } = useSelector((state) => state.widget);

  const settings = connection?.settings ?? {};

  useEffect(() => {
    if (ref?.current) {
      const el = ref.current as HTMLDivElement;
      setHeight(el.offsetHeight);
    }
  }, [ref.current]); //eslint-disable-line

  return (
    <AppBar
      position="absolute"
      color="transparent"
      sx={{
        bottom: 0,
        top: 'auto',
        bgcolor: theme.palette.background.default,
      }}
      // @ts-ignore
      ref={ref}
    >
      <Toolbar>
        {settings.showHomeBtn ? (
          <IconLinkButton
            to="/"
            icon="material-symbols:home-outline-rounded"
            iconActive="material-symbols:home-rounded"
            label="Home"
          />
        ) : (
          ''
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" justifyContent="space-around" flexGrow={5}>
          {settings.wallet ? (
            <IconLinkButton
              to="dummy"
              icon="mdi:wallet-bifold-outline"
              iconActive="mdi:wallet-bifold"
              label="Wallet"
            />
          ) : (
            ''
          )}
          {settings.cart ? (
            <IconLinkButton
              to="/"
              icon="material-symbols:shopping-cart-outline"
              iconActive="material-symbols:shopping-cart"
              badgeContent={totalItems}
              label="Cart"
            />
          ) : (
            ''
          )}
          {settings.profile ? (
            <IconLinkButton
              to="/"
              icon="mdi:account-outline"
              iconActive="mdi:account"
              label="Profile"
            />
          ) : (
            ''
          )}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        {settings.chat ? (
          <Tooltip placement="top" title={`${t('Messenger')}`}>
            <IconButton size="large">
              <NavLink to="/">
                <Avatar
                  color="primary"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    boxShadow: `0 0 10px ${theme.palette.primary.main}`,
                  }}
                >
                  <Iconify icon="mdi:chat-processing" width={24} />
                </Avatar>
              </NavLink>
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    </AppBar>
  );
};

interface IconLinkButtonProps {
  icon: string;
  iconActive: string;
  to: string;
  label: string;
  badgeContent?: number;
}

function IconLinkButton({ icon, iconActive, to, badgeContent, label }: IconLinkButtonProps) {
  const theme = useTheme();
  const { translate: t } = useLocales();
  return (
    <Tooltip title={`${t(label)}`} placement="top">
      <IconButton size="large" sx={{ color: theme.palette.text.secondary }}>
        <NavLink to={to} style={{ display: 'inherit' }}>
          {({ isActive }) => (
            <Badge badgeContent={badgeContent} color="error">
              <Iconify
                icon={isActive ? iconActive : icon}
                color={isActive ? theme.palette.primary.main : theme.palette.text.secondary}
                width={24}
              />
            </Badge>
          )}
        </NavLink>
      </IconButton>
    </Tooltip>
  );
}

export default DefaultFooter;
